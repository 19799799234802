import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { serialize } from "object-to-formdata";

const initialState = {
  roles: [],
  status: "idle",
  error: null,
  statusUpdateColor: "idle",
  statusGetWebsite: "idle",
  color: null,
  websiteConfig: null,
};

export const getAllRoles = createAsyncThunk(
  "roles/getAllRoles",
  async (body) => {
    let data;
    try {
      const response = await axios.get(`/owner/roles${body}`);
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateChatBullColor = createAsyncThunk(
  "roles/updateChatBullColor",
  async (body) => {
    let data;
    const { websiteID, ...rest } = body;
    const Data = serialize(rest, { indices: true });
    try {
      const response = await axios.patch(
        `/owner/websites/${websiteID}/config`,
        Data
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getWebsite = createAsyncThunk(
  "roles/getWebsite",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(
        websiteID
          ? `/owner/websites/${websiteID}/config`
          : `/websites/user/config?domain=${window.location.hostname}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    changeColor: (state, action) => {
      state.color = action.payload;
    },
  },
  extraReducers: {
    [getAllRoles.pending]: (state) => {
      state.status = "loading";
    },
    [getAllRoles.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.roles = action.payload;
    },
    [getAllRoles.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [updateChatBullColor.pending]: (state) => {
      state.statusUpdateColor = "loading";
    },
    [updateChatBullColor.fulfilled]: (state, action) => {
      state.statusUpdateColor = "succeeded";
      state.websiteConfig = action.payload;
    },
    [updateChatBullColor.rejected]: (state) => {
      state.statusUpdateColor = "failed";
    },
    [getWebsite.pending]: (state) => {
      state.statusGetWebsite = "loading";
    },
    [getWebsite.fulfilled]: (state, action) => {
      state.statusGetWebsite = "succeeded";
      state.color = action.payload?.color;
      state.websiteConfig = action.payload;
    },
    [getWebsite.rejected]: (state) => {
      state.statusGetWebsite = "failed";
    },
  },
});
export const reducer = slice.reducer;
export const { changeColor } = slice.actions;
export default slice;
